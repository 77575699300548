<template>
  <v-card class="mx-auto" max-width="500">
    <v-sheet v-if="referenceDocuments.length > 30" class="pa-2 primary lighten-2">
      <v-text-field
        v-model="search"
        label="Search Documents"
        dark
        flat
        solo-inverted
        hide-details
        clearable
        clear-icon="mdi-close-circle-outline"
      ></v-text-field>
    </v-sheet>
    <v-card-actions>
      <v-btn
        :disabled="referenceDocuments.length > 0"
        small
        class="white--text mr-2"
        color="red lighten-6"
        @click="resetSelection"
      >Reset</v-btn>
      
      <v-btn
        small
        class="white--text"
        color="green lighten-1"
        @click="selectItems"
      >Select</v-btn>
    </v-card-actions>

    <v-card-text>
      <v-treeview
        v-if="referenceDocuments.length > 0"
        v-model="connectionBasket"
        :items="referenceDocuments"
        :load-children="loadChildren"
        :search="search"
        selectable
        item-key="key"
        selected-color="indigo"
        open-on-click 
        dense
        expand-icon="mdi-chevron-down"
        on-icon="mdi-bookmark"
        off-icon="mdi-bookmark-outline"
        indeterminate-icon="mdi-bookmark-minus"
        selection-type="independent"
        return-object
      >
        <template #append="{ item }">
          <v-btn v-if="item.body" text @click.stop="showBody(item.body)">
            <small class="text-muted small">Show Details</small>
          </v-btn>
        </template>
      </v-treeview>
    </v-card-text>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import configs from '@/configs'
import Vue, { set } from 'vue'
import DocAdmin from '@/store/data'
import eBus from '@/eventbus'
import types from '@/helpers/types'

export default {
  name: 'DocumentsPicker',
  components: {},
  props: {
    documentTypeName: {
      type: String,
      default: null
    },
    showStanza: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    connectionType: configs.connectionProperties.types,
    referenceDocuments: [],
    loading: true,
    connectionBasket: [],
    dialog: false,
    dialogContent: '',
    search: null
  }),
  computed: {
    ...mapGetters('languages', ['languageNameById']),
    ...mapState('app', ['debug'])
  },
  mounted() {
    console.log(`documentTypeName = ${this.documentTypeName}`)
    this.getReferenceDocuments()
  },
  methods: {
    showBody(body) {
      this.dialogContent = body
      this.dialog = true
    },
    generateItemKey(item) {
      return item.id
    },
    generateTitle(item) {
      if (!item.title) {
        return `${item.type} - ${item.number}`
      }

      if (item.title.length > 3) {
        return item.title
      }

      return `${item.type} - ${item.number}`
    },
    loadChildren(node) {
      return DocAdmin.referenceDocumentItems.list({
        params: {
          parentId: node.documentitemid,
          documentId: node.documentId,
          getStanza: false
        }
      }).then((response) => {
        Vue.set(node, 'children', response.data.map(this.mapDocumentItem))
      }, (err) => {
        console.error(`showChildItems.referenceDocumentItems.error = ${err.message}`)
      })
    },
    mapDocumentItem(documentItem) {
      return {
        id: documentItem.id,
        documentitemid: documentItem.id,
        documentId: documentItem.documentId,
        number: documentItem.number,
        type: documentItem.type,
        connectionTypeId: 5, //DocumentItems
        canBeReferenced: documentItem.canBeReferenced,
        children: documentItem.canBeReferenced ? null : [],
        selectable: documentItem.canBeReferenced ? false : true,
        key: this.generateItemKey(documentItem),
        name: this.generateTitle(documentItem),
        body: documentItem.body,
        isLeafNode: documentItem.isLeafNode
      }
    },
    mapDocument(document) {
      return {
        id: document.id,
        documentId: document.id,
        type: document.type,
        connectionTypeId: 4, //Documents
        name: document.title,
        children: [],
        selectable: false,
        isLeafNode: document.isLeafNode,
        key: this.generateItemKey(document)
      }
    },
    getReferenceDocuments() {
      DocAdmin.referenceDocuments.list({ params: {
        typeName: this.documentTypeName,
        getStanza: this.showStanza
      } }).then((response) => {
        this.referenceDocuments = response.data.map(this.mapDocument)
        this.loading = false
      }, (err) => {
        console.error(`referenceDocuments.error = ${err.message}`)
      })
    },
    selectItems() {
      eBus.$emit('documentspicker_update_selection', this.connectionBasket)
    },
    resetSelection() {
      this.connectionBasket = []
    }
  }
}
</script>

<style scoped>
.text-muted {
  color: #6c757d;
}
</style>