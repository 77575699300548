<template>
  <v-card class="mx-auto" flat>
    <div class="mx-auto text-center">
      <v-btn
        v-for="documentType in documentTypes"
        :key="documentType.documentypeid"
        small
        class="ma-1"
        @click="openComponent(documentType.name)"
      >
        <v-icon v-if="utils.getDocumentTypeIcon(documentType.name)" left small class="pa-1">
          {{ utils.getDocumentTypeIcon(documentType.name) }}
        </v-icon>
        {{ documentType.name }}
      </v-btn>
    </div>

    <div v-if="Object.keys(pendingSaves).length >= 1">
      <v-card v-for="(connectionValue, connectionType) in pendingSaves" :key="connectionType" flat class="pa-2">
        <v-chip
          v-for="(selection, i) in pendingSaves[connectionType]"
          :key="i"
          color="grey"
          dark
          small
          class="pa-1"
          close
          @click:close="removePendingConnection(connectionType, selection.destinationid)"
        >
          <v-icon left small>mdi-connection</v-icon>
          {{ selection.destinationid }}
        </v-chip>
        <v-spacer></v-spacer>
        <small class="red--text">
          <strong>({{ pendingSaves[connectionType].length }}) Unsaved {{ connectionType }} Connection(s)</strong>
        </small>
      </v-card>
    </div>

    <v-card v-if="connections && connections.length >= 1" class="pa-1" flat>
      <v-chip
        v-for="(connection, i) in connections"
        :key="i"
        color="grey"
        dark
        small
        class="pl-2 pr-2 ma-1"
        close
        @click:close="removeLocalConnection(connection)"
      >
        <v-icon left small>mdi-connection</v-icon>
        {{ connection.destination_slug }}
      </v-chip>
    </v-card>

    <div v-if="pendingDeletes.length >= 1">
      <v-card class="pt-1 pl-2 pr-2 pb-2" flat>
        <v-chip
          v-for="(connection, i) in pendingDeletes"
          :key="i"
          color="red"
          dark
          small
          class="mr-1"
        >
          <v-icon left small>mdi-connection</v-icon>
          {{ connection.destination_slug }}
        </v-chip>
        <v-spacer></v-spacer>
        <small class="red--text"><strong>({{ pendingDeletes.length }}) Pending Delete(s)</strong></small>
      </v-card>
    </div>

    <!-- Dynamic component rendering in a modal -->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-btn color="blue darken-1" text @click="dialog = false" style="float:right; margin:10px;">Close</v-btn>
        <v-card-title class="headline">Document Picker</v-card-title>
        <v-card-text>
          <component
            :is="currentComponent"
            v-if="currentComponent"
            :key="currentDocumentTypeName"
            :document-type-name="currentDocumentTypeName"
          ></component>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card-actions v-if="pendingChanges">
      <v-btn  
        small
        text
        class="white--text"
        color="red lighten-6"
        @click="resetPendingChanges"
      >Reset</v-btn>
      <v-btn
        small
        text
        class="white--text"
        color="green lighten-1"
        @click="saveChanges"
      >
        Save
        <v-icon right>mdi-content-save</v-icon>
      </v-btn>
    </v-card-actions>
    
    <div class="text-h6 font-weight-light grey--text pt-2 text-center">
      Create Connections to Documents/Items 
    </div>

    <v-overlay :value="saving">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import configs from '@/configs'
import helperUtils from '@/helpers/utils'
import Vue, { set } from 'vue'
import DocAdmin from '@/store/data'
import Debug from '@/components/common/Debug'
import connectionHelpers from '@/configs/connectionProperties'
import utils from '@/helpers/utils'
import eBus from '@/eventbus'
import DocumentsPicker from '@/components/documents/DocumentsPicker'

export default {
  name: 'Linker',
  components: {
    DocumentsPicker // Register the component
  },
  props: {
    sourceId: {
      type: Number,
      default: null
    },
    sourceConnectionTypeId: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    connectionType: configs.connectionProperties.types,
    saving: false,
    loading: true,
    pendingSaves: {},
    connections: [],
    dialog: false,
    dialogContent: '',
    currentComponent: null, // To hold the current component to be displayed
    currentDocumentTypeName: null, // To hold the current document type ID,
    pendingDeletes: [],
    utils
  }),
  computed: {
    ...mapGetters('languages', ['languageNameById']),
    ...mapState('app', ['debug']),
    ...mapState('documents', ['documentTypes']),
    pendingChanges() {
      return this.pendingDeletes.length > 0 || Object.keys(this.pendingSaves).length > 0
    },
    isResetable() {
      return this.pendingDeletes.length > 0 || Object.keys(this.pendingSaves).length > 0
    }
  },
  async created() {
    eBus.$on('documentspicker_update_selection', this.handleSelectionChange)

    if (this.documentTypes && this.documentTypes.length) { return }
    await this.$store.dispatch('documents/getDocumentTypes')
  },
  mounted() {
    this.getConnections()
  },
  beforeDestroy() {
    eBus.$off('documentspicker_update_selection', this.handleSelectionChange)
  },
  methods: {
    showBody(body) {
      this.dialogContent = body
      this.dialog = true
    },
    getConnectionTypeById(id) {
      return connectionHelpers.getConnectionTypeNameById(Number(id))
    },
    mapConnection(c) {
      return {
        sourceid: c.sourceid,
        destination_slug: c.destination_slug,
        destinationid: c.destinationid,
        source_connectiontypeid: c.source_connectiontypeid,
        destination_connectiontypeid: c.destination_connectiontypeid,
        destination_connectiontypename: this.getConnectionTypeById(c.destination_connectiontypeid),
        connectionpurposeid: c.connectionpurposeid
      }
    },
    mapPickerItem(connection) {
      return {
        sourceid: this.sourceId,
        destinationid: connection.id,
        source_connectiontypeid: this.sourceConnectionTypeId,
        destination_connectiontypeid: connection.connectionTypeId,
        connectionpurposeid: configs.connectionProperties.purpose.link
      }
    },
    openComponent(documentType) {
      this.currentComponent = 'DocumentsPicker'
      this.currentDocumentTypeName = documentType
      this.dialog = true
    },
    getConnections() {
      DocAdmin.connections.list({ params: { 
        sourceid: this.sourceId, 
        source_connectiontypeid: this.sourceConnectionTypeId,
        connectionpurposeid: configs.connectionProperties.purpose.link
      } }).then((response) => {
        this.loading = false
        const data = response.data.results.map(this.mapConnection)

        this.connections = data

      }, (err) => {
        console.error(`getConnectionsTranslations.error = ${err.message}`)
        this.loading = false
      })
    },
    handleSelectionChange(newSelection) {
      Vue.set(this.pendingSaves, this.currentDocumentTypeName, newSelection.map(this.mapPickerItem))
      this.dialog = false
    },
    removeLocalConnection(connection) {
      this.pendingDeletes.push(connection)
      this.connections = this.connections.filter((c) => c.destinationid !== connection.destinationid)
    },
    removePendingConnection(connectionType, destinationId) {
      const updatedConnections = this.pendingSaves[connectionType].filter((c) => c.destinationid !== destinationId)

      if (updatedConnections.length === 0) {
        Vue.delete(this.pendingSaves, connectionType)
      } else {
        Vue.set(this.pendingSaves, connectionType, updatedConnections)
      }
    },
    resetPendingChanges() {
      this.pendingDeletes = []
      this.pendingSaves = {}
      this.getConnections()
    },
    removeConnection(connection) {
      const params = { 
        sourceid: this.sourceId,
        destinationid: connection.destinationid,
        source_connectiontypeid: connection.source_connectiontypeid,
        destination_connectiontypeid: connection.destination_connectiontypeid
      }

      DocAdmin.connections.deleteByParams({ params }).then(() => {
        console.log(`%c ${connection.destination_slug} deleted`, 'color: red;')
      }, (err) => {
        console.error(`deleteConnection.error = ${err.message}`)
      })
    },
    createConnection(connection) {
      DocAdmin.connections.multiCreate(connection).then(() => {
        console.log(`%c ${connection.destination_slug} saved`, 'color: green;')
      }, (err) => {
        console.error(`createConnection.error = ${err.message}`)
      })
    },
    saveChanges() {
      this.saving = true
      this.pendingDeletes.forEach((connection, index) => {
        this.removeConnection(connection)
      })

      Object.keys(this.pendingSaves).forEach((connectionType, index) => {
        this.pendingSaves[connectionType].forEach((selection, subIndex) => {
          this.createConnection(selection)
        })
      })
      setTimeout(() => {
        this.resetPendingChanges()
        this.saving = false
      }, 1000)
    }
  }
}
</script>

<style scoped>
.text-muted {
  color: #6c757d;
}
</style>